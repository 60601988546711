import moment from 'moment-timezone';
import { registerLocale } from 'react-datepicker';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import frCA from 'date-fns/locale/fr-CA';
import de from 'date-fns/locale/de';
import it from 'date-fns/locale/it';
import ptBR from 'date-fns/locale/pt-BR';
import ru from 'date-fns/locale/ru';
import zhCN from 'date-fns/locale/zh-CN';
import ja from 'date-fns/locale/ja';
import pl from 'date-fns/locale/pl';


export const momentUpdateLocale = (locale: string) => {
  switch (locale) {
    case 'en-US':
      moment.updateLocale('en-US', {
        durationLabelsStandard: {
          M: 'months',
          MM: 'months',
          S: 'millisecond',
          SS: 'milliseconds',
          d: 'day',
          dd: 'days',
          h: 'hour',
          hh: 'hours',
          m: 'minute',
          mm: 'minutes',
          s: 'second',
          ss: 'seconds',
          w: 'week',
          ww: 'weeks',
          y: 'year',
          yy: 'years',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
        durationLabelsShort: {
          M: 'm',
          MM: 'ms',
          S: 'ms',
          SS: 'ms',
          d: 'd',
          dd: 'ds',
          h: 'h',
          hh: 'hrs',
          m: 'min',
          mm: 'minutes',
          s: 's',
          ss: 'secs',
          w: 'w',
          ww: 'ws',
          y: 'y',
          yy: 'yrs',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
      });
      registerLocale('en-US', enUS);
      break;
    case 'fr':
      moment.updateLocale('fr', {
        durationLabelsStandard: {
          M: 'mois',
          MM: 'mois',
          S: 'milliseconde',
          SS: 'millisecondes',
          d: 'jour',
          dd: 'jours',
          h: 'heure',
          hh: 'heures',
          m: 'minute',
          mm: 'minutes',
          s: 'seconde',
          ss: 'secondes',
          w: 'semaine',
          ww: 'semaines',
          y: 'année',
          yy: 'années',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
        durationLabelsShort: {
          M: 'm',
          MM: 'ms',
          S: 'msec',
          SS: 'msecs',
          d: 'j',
          dd: 'js',
          h: 'h',
          hh: 'hrs',
          m: 'min',
          mm: 'minutes',
          s: 'sec',
          ss: 'secs',
          w: 'sem.',
          ww: 'sems.',
          y: 'an',
          yy: 'ans',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
      });
      registerLocale('fr', fr);
      break;
    case 'fr-CA':
      moment.updateLocale('fr-ca', {
        durationLabelsStandard: {
          M: 'mois',
          MM: 'mois',
          S: 'milliseconde',
          SS: 'millisecondes',
          d: 'jour',
          dd: 'jours',
          h: 'heure',
          hh: 'heures',
          m: 'minute',
          mm: 'minutes',
          s: 'seconde',
          ss: 'secondes',
          w: 'semaine',
          ww: 'semaines',
          y: 'année',
          yy: 'années',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
        durationLabelsShort: {
          M: 'm.',
          MM: 'm.',
          S: 'ms',
          SS: 'ms',
          d: 'j',
          dd: 'j',
          h: 'h',
          hh: 'h',
          m: 'min',
          mm: 'min',
          s: 's',
          ss: 's',
          w: 'sem.',
          ww: 'sem.',
          y: 'an',
          yy: 'ans',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
      });
      registerLocale('fr-CA', frCA);

      break;
    case 'it':
      moment.updateLocale('it', {
        durationLabelsStandard: {
          M: 'mese',
          MM: 'mesi',
          S: 'millisecondo',
          SS: 'millisecondi',
          d: 'giorno',
          dd: 'giorni',
          h: 'ora',
          hh: 'ore',
          m: 'minuto',
          mm: 'minuti',
          s: 'secondo',
          ss: 'secondi',
          w: 'settimana',
          ww: 'settimane',
          y: 'anno',
          yy: 'anni',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
        durationLabelsShort: {
          M: 'lu',
          MM: 'mes',
          S: 'msec',
          SS: 'msec',
          d: 'g',
          dd: 'gg',
          h: 'ora',
          hh: 'ore',
          m: 'min',
          mm: 'minuti',
          s: 'sec',
          ss: 'secondi',
          w: 'sett',
          ww: 'sett.',
          y: 'an',
          yy: 'anni',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
      });
      registerLocale('it', it);

      break;
    case 'ja':
      moment.updateLocale('ja', {
        durationLabelsStandard: {
          M: '月',
          MM: '月',
          S: 'ミリ秒',
          SS: 'ミリ秒',
          d: '日',
          dd: '日',
          h: '時間',
          hh: '時間',
          m: '分',
          mm: '分',
          s: '秒',
          ss: '秒',
          w: '週間',
          ww: '週間',
          y: '年',
          yy: '年',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
        durationLabelsShort: {
          M: '月',
          MM: '月',
          S: 'ミリ秒',
          SS: 'ミリ秒',
          d: '日',
          dd: '日',
          h: '時間',
          hh: '時間',
          m: '分',
          mm: '分',
          s: '秒',
          ss: '秒',
          w: '週間',
          ww: '週間',
          y: '年',
          yy: '年',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
      });
      registerLocale('ja', ja);
      break;
    case 'pt-BR':
      moment.updateLocale('pt-br', {
        durationLabelsStandard: {
          M: 'mês',
          MM: 'meses',
          S: 'milissegundo',
          SS: 'milissegundos',
          d: 'dia',
          dd: 'dias',
          h: 'hora',
          hh: 'horas',
          m: 'minuto',
          mm: 'minutos',
          s: 'segundo',
          ss: 'segundos',
          w: 'semana',
          ww: 'semanas',
          y: 'ano',
          yy: 'anos',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
        durationLabelsShort: {
          M: 'ms',
          MM: 'mss',
          S: 'mseg',
          SS: 'msegs',
          d: 'dia',
          dd: 'dias',
          h: 'hr',
          hh: 'hrs',
          m: 'min',
          mm: 'mins',
          s: 'seg',
          ss: 'segs',
          w: 'sem',
          ww: 'sems',
          y: 'ano',
          yy: 'anos',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
      });
      registerLocale('pt-BR', ptBR);
      break;
    case 'zh-CN':
      moment.updateLocale('zh-cn', {
        durationLabelsStandard: {
          M: '月',
          MM: '月',
          S: '毫秒',
          SS: '毫秒',
          d: '天',
          dd: '天',
          h: '小时',
          hh: '小时',
          m: '分钟',
          mm: '分钟',
          s: '秒',
          ss: '秒',
          w: '周',
          ww: '星期',
          y: '年',
          yy: '年',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
        durationLabelsShort: {
          M: '月',
          MM: '月',
          S: '毫秒',
          SS: '毫秒',
          d: '天',
          dd: '天',
          h: '小时',
          hh: '小时',
          m: '分钟',
          mm: '分钟',
          s: '秒',
          ss: '秒',
          w: '周',
          ww: '周',
          y: '年',
          yy: '年',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
      });
      registerLocale('zh-CN', zhCN);

      break;
    case 'es':
      moment.updateLocale('es', {
        durationLabelsStandard: {
          M: 'mes',
          MM: 'meses',
          S: 'milisegundo',
          SS: 'milisegundos',
          d: 'día',
          dd: 'días',
          h: 'hora',
          hh: 'horas',
          m: 'minuto',
          mm: 'minutos',
          s: 'segundo',
          ss: 'segundos',
          w: 'semana',
          ww: 'semanas',
          y: 'año',
          yy: 'años',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
        durationLabelsShort: {
          M: 'm',
          MM: 'ms',
          S: 'mseg',
          SS: 'msegs',
          d: 'd',
          dd: 'ds',
          h: 'h',
          hh: 'hs',
          m: 'min',
          mm: 'mins',
          s: 'seg',
          ss: 'segs',
          w: 'sem',
          ww: 'sems',
          y: 'añ',
          yy: 'añs',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
      });
      registerLocale('es', es);

      break;
    case 'de':
      moment.updateLocale('de', {
        durationLabelsStandard: {
          M: 'Monat',
          MM: 'Monate',
          S: 'Millisekunde',
          SS: 'Millisekunden',
          d: 'Tag',
          dd: 'Tage',
          h: 'Stunde',
          hh: 'Stunden',
          m: 'Minute',
          mm: 'Minuten',
          s: 'Sekunde',
          ss: 'Sekunden',
          w: 'Woche',
          ww: 'Wochen',
          y: 'Jahr',
          yy: 'Jahre',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
        durationLabelsShort: {
          M: 'M.',
          MM: 'Mon.',
          S: 'Ms.',
          SS: 'Ms.',
          d: 'T.',
          dd: 'T.',
          h: 'St.',
          hh: 'Stunden',
          m: 'Min.',
          mm: 'Minuten',
          s: 'Sek.',
          ss: 'Sek.',
          w: 'W.',
          ww: 'W.',
          y: 'J.',
          yy: 'J.',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
      });
      registerLocale('de', de);
      break;
    case 'ru':
      moment.updateLocale('ru', {
        durationLabelsStandard: {
          M: 'месяц',
          MM: 'мес.',
          S: 'миллисекунда',
          SS: 'мс',
          d: 'день',
          dd: 'дн.',
          h: 'час',
          hh: 'ч.',
          m: 'минута',
          mm: 'мин.',
          s: 'секунда',
          ss: 'сек.',
          w: 'неделя',
          ww: 'нед.',
          y: 'год',
          yy: 'года/лет',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
        durationLabelsShort: {
          M: 'мес.',
          MM: 'мес.',
          S: 'мс',
          SS: 'мс',
          d: 'д',
          dd: 'дд',
          h: 'ч.',
          hh: 'ч.',
          m: 'мин.',
          mm: 'мин.',
          s: 'сек.',
          ss: 'сек.',
          w: 'нед.',
          ww: 'нед.',
          y: 'г.',
          yy: 'гг.',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
      });
      registerLocale('ru', ru);
      break;
    case 'pl':
      moment.updateLocale('pl', {
        durationLabelsStandard: {
          M: 'miesiąc',
          MM: 'miesiące',
          S: 'milisekunda',
          SS: 'milisekundy',
          d: 'dzień',
          dd: 'dni',
          h: 'godzina',
          hh: 'godziny',
          m: 'minuta',
          mm: 'minuty',
          s: 'sekunda',
          ss: 'sekundy',
          w: 'tydzień',
          ww: 'tygodnie',
          y: 'rok',
          yy: 'lata',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
        durationLabelsShort: {
          M: 'mies.',
          MM: 'mies.',
          S: 'mies.',
          SS: 'mies.',
          d: 'dz.',
          dd: 'dni',
          h: 'godz',
          hh: 'godz',
          m: 'min.',
          mm: 'minuty',
          s: 'sek.',
          ss: 'sek.',
          w: 'tydz.',
          ww: 'tyg.',
          y: 'rok',
          yy: 'lat',
          SSS: '',
          sss: '',
          mmm: '',
          hhh: '',
          ddd: '',
          www: '',
          MMM: '',
          yyy: ''
        },
      });
      registerLocale('pl', pl);
      break;
      
    // default:
    // // code block
  }
};