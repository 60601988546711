import 'react-hot-loader';
import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css?raw';
import 'text-security/text-security.css?raw';
import 'rc-slider/assets/index.css?raw';
import { BrowserRouter } from 'react-router-dom';
import configureStore from 'src/app/redux/store/configureStore';
// common styles
import 'bootstrap/dist/css/bootstrap.min.css?raw';
import './index.css?raw';
import './styles/buttons.css?raw';
import './styles/cards.css?raw';
import './styles/forms.css?raw';
import './styles/subnavigation.css?raw';
import './styles/tables.css?raw';
import './styles/react-dates-overrides.css?raw';
import './styles/date-picker.css?raw';
import './styles/video-react.css?raw';
import './styles/slick/slick.css?raw';
import './styles/slick/slick-theme.css?raw';
// common libs
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
import App from 'src/App';
import { I18nProvider } from './i18n';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { launchDarklyClientId } from 'src/app/redux/modules/session/config';
import MatomoWrapper from 'src/components/MatomoWrapper/MatomoWrapper';

import 'src/styles/froala.css?raw';

momentDurationFormatSetup(moment);

const store = configureStore();

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyClientId(),
    reactOptions: {
      useCamelCaseFlagKeys: true,
    },
    options: {
      bootstrap: 'localStorage',
      sendEvents: false,
      diagnosticOptOut: true,
    },
  });

  render(
    <Provider store={store}>
      <BreadcrumbsProvider>
        <BrowserRouter>
          <I18nProvider>
            <MatomoWrapper>
              <LDProvider>
                <App />
              </LDProvider>
            </MatomoWrapper>
          </I18nProvider>
        </BrowserRouter>
      </BreadcrumbsProvider>
    </Provider>,
    document.getElementById('root') as HTMLElement
  );
};

renderApp();
