export const LOCALES = {
  'English (United States)': 'en-US',
  'Français (French)': 'fr',
  'Français Canada (French Canada)': 'fr-CA',
  'Deutsch (German)':'de',
  'Español (Spanish)': 'es',
  'Italiano (Italian)': 'it',
  'Português Brasil (Portuguese Brazil)': 'pt-BR',
  'русский (Russian)': 'ru',
  '简体中文 (Chinese Simplified)': 'zh-CN',
  '日本語 (Japanese)' : 'ja',
  'Polski (Polish)': 'pl',

};